<template>
  <!-- 界面导航栏 -->
  <div>
    <div class="mainInterface_navigation_btn">
      <div>
        <i
          class="el-icon-arrow-right"
          style="font-weight:bold;cursor:pointer"
          @click="onSubmitFold"
        ></i>
        导航设置
      </div>
      <el-button
        type="primary"
        round
        size="small"
        style="width:100px"
        @click="onSubmitEditMainInterface"
      >保存</el-button>
    </div>
    <div class="mainInterface_navigation_view">
      <div
        :class="index==navigationIndex?'mainInterface_navigation_selected':'mainInterface_navigation'"
        v-for="(navigation,index) in navigationList"
        :key="index"
        :id="index"
        @click="onSubmitSelected(index)"
      >
        <div
          :class="index==navigationIndex?'mainInterface_navigation_top_btn_selected':'mainInterface_navigation_top_btn'"
        >
          <div>
            <el-button
              type="text"
              size="mini"
              @click="upSortNavigation(index)"
              v-if="index !=0"
              icon="el-icon-top"
            >上移</el-button>
            <el-button
              type="text"
              size="mini"
              @click="downSortNavigation(index)"
              v-if="navigationList.length-1 !=index"
              icon="el-icon-bottom"
            >下移</el-button>
          </div>
          <el-button
            type="text"
            size="mini"
            icon="el-icon-close"
            v-if="navigationList.length-1 !=0"
            @click="delNavigation(navigation,index)"
          ></el-button>
        </div>

        <el-form
          ref="navigationForm"
          label-position="left"
          label-width="85px"
          size="small"
          style="padding:5px 20px"
        >
          <el-form-item label="图标">
            <el-dropdown placement="top" trigger="click">
              <div class="mainInterface_navigation_logo">
                <i
                  class="el-icon-plus mainInterface_navigation_logo_icon"
                  v-if="!navigation.iconUrl"
                ></i>
                <el-image
                  v-else
                  :src="fileUrl+navigation.iconUrl"
                  style="width: 60px; height: 60px;background:rgba(235,235,235,0.5);cursor: pointer;"
                  fit="contain"
                ></el-image>
              </div>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item
                  @click.native="dialogEditIconFileUpload=true"
                  style="padding:0"
                >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;资源库选择&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</el-dropdown-item>
                <el-dropdown-item style="padding:0">
                  <el-upload
                    style="text-align:center"
                    :action="actionUrl"
                    accept=".jpg, .jpeg, .png, .JPG, .JPEG"
                    :headers="header"
                    :data="{type:0,category:'图标'}"
                    :show-file-list="false"
                    :on-success="handleEditIconSuccess"
                    :before-upload="beforeEditIconUpload"
                  >本地上传</el-upload>
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </el-form-item>
          <el-form-item label="标题">
            <el-input v-model="navigation.title"></el-input>
          </el-form-item>
          <el-form-item label="跳转类型">
            <el-select v-model="navigation.reqType" @change="switchReqType">
              <el-option
                v-for="item in reqOption"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            label="展示方式"
            v-if="navigation.reqType=='img' || navigation.reqType=='video' || navigation.reqType=='qrCode'"
          >
            <el-radio v-model="navigation.showType" label="fullscreen">全屏</el-radio>
            <el-radio v-model="navigation.showType" label="alert">弹窗</el-radio>
          </el-form-item>
          <!-- APK跳转 -->
          <el-form-item label="跳转APK" v-if="navigation.reqType=='apk'">
            <p>{{navigation.apkName}}</p>
            <div class="mainInterface_navigation_upload">
              <div>
                <el-button
                  type="primary"
                  size="mini"
                  style="width:80px"
                  @click="dialogEditApplication=true"
                >资源库</el-button>
              </div>
              <div>
                <el-upload
                  ref="apk-upload"
                  :action="actionUrlApk"
                  accept=".apk"
                  :on-success="handleApkSuccess"
                  :before-upload="handleApkBefore"
                  :headers="header"
                  :show-file-list="true"
                >
                  <el-button plain type="primary" size="mini" style="width:80px;">本地上传</el-button>
                </el-upload>
              </div>
            </div>
          </el-form-item>
          <!-- 内置页面跳转 -->
          <el-form-item label="跳转页面" v-if="navigation.reqType=='page'">
            <el-select v-model="navigation.pageId">
              <el-option
                v-for="item in contentOption"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <!-- 二维码跳转 -->
          <el-form-item label="格式" v-if="navigation.reqType=='qrCode'">
            <el-radio v-model="navigation.reqQrType" label="link">链接</el-radio>
            <el-radio v-model="navigation.reqQrType" label="img">图片</el-radio>
          </el-form-item>
          <el-form-item
            label="链接地址"
            v-if="navigation.reqType=='qrCode' && navigation.reqQrType=='link'"
          >
            <el-input v-model="navigation.qrUrl"></el-input>
          </el-form-item>
          <el-form-item
            label="二维码图片"
            v-if="navigation.reqType=='qrCode' && navigation.reqQrType=='img'"
          >
            <div class="mainInterface_navigation_img" v-if="navigation.reqQr">
              <el-image
                style="width: 80px; height: 80px"
                :src="fileUrl+navigation.reqQr"
                fit="contain"
              ></el-image>
            </div>
            <div class="mainInterface_navigation_upload">
              <div>
                <el-button
                  type="primary"
                  size="mini"
                  style="width:80px"
                  @click="dialogEditQrFileUpload=true"
                >资源库</el-button>
              </div>
              <div>
                <el-upload
                  :action="actionUrl"
                  accept=".jpg, .jpeg, .png, .JPG, .JPEG"
                  :headers="header"
                  :data="{type:0,category:'图片'}"
                  :show-file-list="false"
                  :on-success="handleEditQrSuccess"
                  :before-upload="beforeEditQrUpload"
                >
                  <el-button plain type="primary" size="mini" style="width:80px">本地上传</el-button>
                </el-upload>
              </div>
            </div>
          </el-form-item>
          <!-- 图片跳转 -->
          <el-form-item label="跳转图片" v-if="navigation.reqType=='img'">
            <div class="mainInterface_navigation_img" v-if="navigation.reqImgUrl">
              <el-image
                style="width: 80px; height: 80px"
                :src="fileUrl+navigation.reqImgUrl"
                fit="contain"
              ></el-image>
            </div>

            <!-- <el-row :gutter="0" v-if="navigation.imgList.length>0">
              <el-col
                :xl="12"
                :lg="12"
                :md="12"
                :sm="24"
                :xs="24"
                :offset="0"
                v-for="(item,index) in navigation.imgList"
                :key="index"
                style="margin-bottom:10px;"
              >
                <div class="mainInterface_navigation_img">
                  <el-image style="width: 80px; height: 80px" :src="fileUrl+item" fit="contain"></el-image>
                  <label @click="delNavigationImg(index)" class="delImgIcon">
                    <i class="el-icon-delete" style="transform:rotate(-45deg); color:#FFFFFF;"></i>
                  </label>
                </div>
              </el-col>
            </el-row>-->
            <div class="mainInterface_navigation_upload">
              <div>
                <el-button
                  type="primary"
                  size="mini"
                  style="width:80px"
                  @click="dialogEditImgFileUpload=true"
                >资源库</el-button>
              </div>
              <div>
                <el-upload
                  :action="actionUrl"
                  accept=".jpg, .jpeg, .png, .JPG, .JPEG"
                  :headers="header"
                  :data="{type:0,category:'图片'}"
                  :show-file-list="false"
                  :on-success="handleEditImgSuccess"
                  :before-upload="beforeEditImgUpload"
                  multiple
                  :limit="9"
                >
                  <el-button plain type="primary" size="mini" style="width:80px">本地上传</el-button>
                </el-upload>
              </div>
            </div>
          </el-form-item>
          <!-- 视频跳转 -->
          <el-form-item label="跳转视频" v-if="navigation.reqType=='video'">
            <video
              v-if="navigation.reqVideoUrl"
              :src="fileUrl+navigation.reqVideoUrl"
              controls="controls"
              style="width:100%;"
            ></video>
            <div class="mainInterface_navigation_upload">
              <div>
                <el-button
                  type="primary"
                  size="mini"
                  style="width:80px"
                  @click="dialogEditVideoFileUpload=true"
                >资源库</el-button>
              </div>
              <div>
                <el-upload
                  :action="actionUrl"
                  accept=".mp4, .flv, .ts, .MP4, .FLV, .TS"
                  :headers="header"
                  :data="{type:1,category:'背景视频'}"
                  :show-file-list="false"
                  :on-success="handleEditVideoSuccess"
                  :before-upload="beforeEditVideoUpload"
                >
                  <el-button plain type="primary" size="mini" style="width:80px">本地上传</el-button>
                </el-upload>
              </div>
            </div>
          </el-form-item>
          <el-form-item label="网页地址" v-if="navigation.reqType=='url'">
            <el-input v-model="navigation.url"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <div style="text-align:center">
        <el-button type="primary" size="small" @click="addNavigation">新增导航</el-button>
      </div>
    </div>

    <!-- 导航图标 -->
    <el-dialog
      append-to-body
      :visible.sync="dialogEditIconFileUpload"
      :close-on-click-modal="false"
      width="55%"
      @closed="closeDialogEditImgFileUpload"
    >
      <span slot="title">资源库</span>
      <FileUpload :choice="true" :type="0" :category="'图标'" v-if="dialogEditIconFileUpload"></FileUpload>
      <div slot="footer" class="dialog-footer-button">
        <el-button plain type="primary" @click="dialogEditIconFileUpload = false" size="small">取 消</el-button>
        <el-button type="primary" @click="onSubmitEditIcon" size="small">确 定</el-button>
      </div>
    </el-dialog>

    <!-- 导航图片 -->
    <el-dialog
      append-to-body
      :visible.sync="dialogEditImgFileUpload"
      :close-on-click-modal="false"
      width="55%"
      @closed="closeDialogEditImgFileUpload"
    >
      <span slot="title">资源库</span>
      <FileUpload
        :choice="false"
        :count="9"
        :type="0"
        :category="'图片'"
        v-if="dialogEditImgFileUpload"
      ></FileUpload>
      <div slot="footer" class="dialog-footer-button">
        <el-button plain type="primary" @click="dialogEditImgFileUpload = false" size="small">取 消</el-button>
        <el-button type="primary" @click="onSubmitEditImg" size="small">确 定</el-button>
      </div>
    </el-dialog>

    <!-- 导航二维码图片  -->
    <el-dialog
      append-to-body
      :visible.sync="dialogEditQrFileUpload"
      :close-on-click-modal="false"
      width="55%"
      @closed="closeDialogEditImgFileUpload"
    >
      <span slot="title">资源库</span>
      <FileUpload :choice="true" :type="0" :category="'图片'" v-if="dialogEditQrFileUpload"></FileUpload>
      <div slot="footer" class="dialog-footer-button">
        <el-button plain type="primary" @click="dialogEditQrFileUpload = false" size="small">取 消</el-button>
        <el-button type="primary" @click="onSubmitEditQr" size="small">确 定</el-button>
      </div>
    </el-dialog>

    <!-- 导航视频 -->
    <el-dialog
      append-to-body
      :visible.sync="dialogEditVideoFileUpload"
      :close-on-click-modal="false"
      width="55%"
    >
      <span slot="title">资源库</span>
      <FileUploadVideo
        :type="1"
        :category="'背景视频'"
        v-on:callBackPath="onSubmitEditVideoUrl"
        v-if="dialogEditVideoFileUpload"
      ></FileUploadVideo>
    </el-dialog>

    <!-- 导航apk -->
    <el-dialog
      append-to-body
      :visible.sync="dialogEditApplication"
      :close-on-click-modal="false"
      width="50%"
      @closed="closeDialogEditApplication"
    >
      <span slot="title">新增应用</span>
      <Application v-if="dialogEditApplication" :choice="false"></Application>
      <span slot="footer">
        <el-button plain type="primary" size="small" @click="dialogEditApplication=false">取 消</el-button>
        <el-button type="primary" size="small" @click="onSubmitEditApplication">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import Aes from "../utils/aes";
import {
  addAppStoreApi,
  getContentPageApi,
  delMainInterfaceNavigationApi
} from "../api/data";
import FileUpload from "./FileUpload";
import Application from "./Application";
import FileUploadVideo from "./FileUploadVideo";
export default {
  components: {
    FileUpload,
    Application,
    FileUploadVideo
  },
  data() {
    return {
      dialogEditIconFileUpload: false, //导航图标窗口
      navigationIndex: "", //导航下标
      reqOption: [
        { value: "apk", label: "APK跳转" },
        { value: "page", label: "内置页面跳转" },
        { value: "qrCode", label: "二维码跳转" },
        { value: "img", label: "图片跳转" },
        { value: "video", label: "视频跳转" },
        { value: "url", label: "网页跳转" }
      ],
      dialogEditApplication: false, //导航APK窗口
      dialogEditImgFileUpload: false, //导航图片窗口
      dialogEditQrFileUpload: false, //导航二维码图片窗口
      dialogEditVideoFileUpload: false, //导航视频窗口
      contentOption: [] //内容页面选项
    };
  },
  props: {
    isShowWindow: Boolean,
    isSelectedNavigation: Number,
    navigationList: []
  },
  watch: {
    isSelectedNavigation(newVal) {
      this.goTest(newVal);
      this.navigationIndex = newVal;
    }
  },
  computed: {
    placesInfo() {
      return JSON.parse(Aes.decode(this.$store.state.user.placesInfo));
    },
    header() {
      //上传头部POST请求头部带token信息验证登录状态
      return {
        accessToken: this.$store.state.user.authorization
      };
    },
    //图片显示地址
    fileUrl() {
      return this.$store.state.fileUrl;
    },
    //上传路径
    actionUrl() {
      return this.$store.state.fileUrl + "/file/upload";
    },
    actionUrlApk() {
      return this.$store.state.fileUrl + "/user_apk/upload";
    }
  },
  created() {
    this.initContentPage();
    this.navigationIndex = this.isSelectedNavigation;
    setTimeout('location.hash="#' + this.isSelectedNavigation + '";', 500);
  },
  methods: {
    //跳转指定锚点
    goTest(id) {
      location.hash = "#" + id;
    },
    //初始化内容页面选项
    initContentPage() {
      getContentPageApi({ groupId: this.placesInfo.groupId }).then(res => {
        if (res) this.contentOption = res.data;
      });
    },
    //新增导航
    addNavigation() {
      this.$emit("callbackAddNavigation", this.navigationList);
    },
    //上移
    upSortNavigation(index) {
      if (index != 0) {
        this.navigationList[index].sort = this.navigationList[index].sort - 1;
        this.navigationList[index - 1].sort =
          this.navigationList[index - 1].sort + 1;
        this.navigationList = this.sortArray(this.navigationList, "sort");
      }
    },
    //下移
    downSortNavigation(index) {
      if (this.navigationList.length - 1 != index) {
        this.navigationList[index].sort = this.navigationList[index].sort + 1;
        this.navigationList[index + 1].sort =
          this.navigationList[index + 1].sort - 1;
        this.navigationList = this.sortArray(this.navigationList, "sort");
      }
    },
    //删除导航
    delNavigation(obj, index) {
      this.$confirm("此操作将永久删除该导航的所有内容, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        delMainInterfaceNavigationApi({ id: obj.id }).then(res => {
          if (res) {
            // this.$message.success(res.msg);
            // this.initNavigation();
            this.onSubmitEditMainInterface();
            this.navigationList.splice(index, 1);
          }
        });
      });
    },
    //提交编辑
    onSubmitEditMainInterface() {
      this.$emit("callbackNavigationList", this.navigationList);
    },
    //折叠
    onSubmitFold() {
      this.$emit("callbackIsShowWindow", !this.isShowWindow);
    },
    //选中卡片
    onSubmitSelected(index) {
      this.navigationIndex = index;
      // this.$emit("callbackIsSelected", index);
    },

    //选择不同的跳转类型
    switchReqType(val) {
      switch (val) {
        case "apk":
          this.navigationList[this.navigationIndex].showType = "fullscreen";
          break;
        case "page":
          this.navigationList[this.navigationIndex].showType = "fullscreen";
          break;
        case "url":
          this.navigationList[this.navigationIndex].showType = "fullscreen";
          break;
        default:
          this.navigationList[this.navigationIndex].showType = "alert";
          break;
      }
    },
    //图片窗口关闭事件
    closeDialogEditImgFileUpload() {
      this.$store.commit("setFileList", []);
    },
    //上传导航图标完成事件
    handleEditIconSuccess(res) {
      var result = JSON.parse(Aes.decode(res));
      if (result.type == "success") {
        this.navigationList[this.navigationIndex].iconUrl = result.msg;
      } else {
        this.$message.error(result.msg);
      }
    },
    //上传导航图标前事件
    beforeEditIconUpload(file) {
      const isLt5M = file.size / 1024 / 1024 < 5;
      if (!isLt5M) {
        this.$message.warning("上传图片大小不能超过 5MB!");
      }
      return isLt5M;
    },
    //资源库导航图标添加完成事件
    onSubmitEditIcon() {
      this.dialogEditIconFileUpload = false;
      if (this.$store.state.fileList.length > 0) {
        this.navigationList[
          this.navigationIndex
        ].iconUrl = this.$store.state.fileList[0].filePath;
        this.$store.commit("setFileList", []);
      }
    },
    //删除跳转图片集合
    // delNavigationImg(index) {
    //   this.navigationList[this.navigationIndex].imgList.splice(index, 1);
    // },
    //上传导航图片完成事件
    handleEditImgSuccess(res) {
      var result = JSON.parse(Aes.decode(res));
      if (result.type == "success") {
        this.navigationList[this.navigationIndex].reqImgUrl = result.msg;
      } else {
        this.$message.error(result.msg);
      }
    },
    //上传导航图片前事件
    beforeEditImgUpload(file) {
      const isLt5M = file.size / 1024 / 1024 < 5;
      if (!isLt5M) {
        this.$message.warning("上传图片大小不能超过 5MB!");
      }
      return isLt5M;
    },
    //资源库导航图片添加事件
    onSubmitEditImg() {
      this.dialogEditImgFileUpload = false;
      if (this.$store.state.fileList.length > 0) {
        for (let i = 0; i < this.$store.state.fileList.length; i++) {
          this.navigationList[
            this.navigationIndex
          ].reqImgUrl = this.$store.state.fileList[0].filePath;
        }
        this.$store.commit("setFileList", []);
      }
    },

    //上传导航视频完成事件
    handleEditVideoSuccess(res) {
      var result = JSON.parse(Aes.decode(res));
      if (result.type == "success") {
        this.navigationList[this.navigationIndex].reqVideoUrl = result.msg;
      } else {
        this.$message.error(result.msg);
      }
    },
    //上传导航视频前事件
    beforeEditVideoUpload(file) {
      const isLt5M = file.size / 1024 / 1024 < 50;
      if (!isLt5M) {
        this.$message.warning("上传图片大小不能超过 50MB!");
      }
      return isLt5M;
    },
    //资源库导航视频添加事件
    onSubmitEditVideoUrl(path) {
      this.dialogEditVideoFileUpload = false;
      this.navigationList[this.navigationIndex].reqVideoUrl = path;
    },

    //上传导航二维码完成事件
    handleEditQrSuccess(res) {
      var result = JSON.parse(Aes.decode(res));
      if (result.type == "success") {
        this.navigationList[this.navigationIndex].reqQr = result.msg;
      } else {
        this.$message.error(result.msg);
      }
    },
    //上传导航二维码前事件
    beforeEditQrUpload(file) {
      const isLt5M = file.size / 1024 / 1024 < 5;
      if (!isLt5M) {
        this.$message.warning("上传图片大小不能超过 5MB!");
      }
      return isLt5M;
    },
    //资源库导航二维码添加完成事件
    onSubmitEditQr() {
      this.dialogEditQrFileUpload = false;
      if (this.$store.state.fileList.length > 0) {
        this.navigationList[
          this.navigationIndex
        ].reqQr = this.$store.state.fileList[0].filePath;
        this.$store.commit("setFileList", []);
      }
    },

    //关闭导航apk窗口
    closeDialogEditApplication() {
      this.$store.commit("setFileApkList", []);
    },
    //添加上传APK
    //资源库添加应用列表
    onSubmitEditApplication() {
      if (this.$store.state.fileApkList.length > 0) {
        this.navigationList[
          this.navigationIndex
        ].apkId = this.$store.state.fileApkList[0].apkId;
        this.navigationList[
          this.navigationIndex
        ].apkName = this.$store.state.fileApkList[0].cnName;
        this.$store.commit("setFileApkList", []);
        this.dialogEditApplication = false;
      }
    },
    //上传前验证判断
    handleApkBefore(file) {
      const isLt50M = file.size / 1024 / 1024 < 50;
      if (!isLt50M) {
        this.$message.warning("上传APK大小不能超过 50MB!");
      }
      return isLt50M;
    },
    //上传成功
    handleApkSuccess(response) {
      const res = JSON.parse(Aes.decode(response));
      if (res.type == "success") {
        if (res.msg == "equal") {
          //查询到有相同的APK包提示是否覆盖
          this.$confirm("资源库中存在相同APP,是否继续上传?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning"
          }).then(() => {
            //确认覆盖就将返回的带APPid对象存入
            res.data.apkType = "我的";
            this.onSubmitAddApk(res.data);
          });
        } else {
          res.data.publicity = "on";
          res.data.apkType = "我的";
          this.onSubmitAddApk(res.data);
        }
      } else {
        this.$message.error(res.msg);
      }
      // 页面上存在多个upload时使用此方法清除
      const mainImg = this.$refs["apk-upload"];
      if (mainImg && mainImg.length) {
        mainImg.forEach(item => {
          item.clearFiles();
        });
      }
    },
    //添加APK
    onSubmitAddApk(obj) {
      addAppStoreApi(obj).then(res => {
        if (res) {
          //等待后台返回
          this.navigationList[this.navigationIndex].apkId = res.data.apkId;
          this.navigationList[this.navigationIndex].apkName = res.data.cnName;
          this.$message.success(res.msg);
        }
      });
    },
    //排序
    sortArray(array, key) {
      return array.sort(function(a, b) {
        var x = a[key];
        var y = b[key];
        return x < y ? -1 : x > y ? 1 : 0;
      });
    }
  }
};
</script>
<style lang='scss'>
.mainInterface_navigation_view {
  padding: 20px;
  overflow-y: scroll;
  height: calc(100vh - 120px);
  width: 100%;
}
.mainInterface_navigation_view::-webkit-scrollbar {
  width: 6px;
}
.mainInterface_navigation_view:hover::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.8);
}
.mainInterface_navigation_view::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 6px;
}
.mainInterface_navigation_btn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 700;
  border-bottom: 1px solid #d7dae2;
  padding: 10px;
}

.mainInterface_navigation {
  border: 1px solid #ebeef5;
  border-radius: 6px;
  margin-bottom: 10px;
}
.mainInterface_navigation:hover {
  box-shadow: 0 2px 12px 0 #619aec8c;
}
.mainInterface_navigation_selected {
  border: 1px solid #ebeef5;
  border-radius: 6px;
  margin-bottom: 10px;
  background: #f3f7fb;
}
.mainInterface_navigation_top_btn {
  background: rgb(244, 244, 245);
  border-radius: 6px 6px 0 0;
  display: flex;
  justify-content: space-between;
  padding: 0 10px;
}
.mainInterface_navigation_top_btn_selected {
  background: rgb(233, 233, 235);
  border-radius: 6px 6px 0 0;
  display: flex;
  justify-content: space-between;
  padding: 0 10px;
}
.mainInterface_navigation_logo {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  position: relative;
  overflow: hidden;
  width: 60px;
  height: 60px;
}
.mainInterface_navigation_logo:hover {
  border-color: #5e72f9;
}
.mainInterface_navigation_logo_icon {
  font-size: 28px;
  color: #8c939d;
  width: 60px;
  height: 60px;
  line-height: 60px;
  text-align: center;
}

.mainInterface_navigation_img {
  position: relative;
  cursor: pointer;
  overflow: hidden;
  width: 80px;
  height: 80px;
  background: rgba(235, 235, 235, 0.5);
  border-radius: 6px;
  border: 1px dashed #d9d9d9;
}
.mainInterface_navigation_upload {
  display: flex;
  justify-content: space-between;
}
.delImgIcon {
  width: 50px;
  height: 30px;
  background: #f56c6c;
  transform: rotate(45deg);
  position: absolute;
  right: -20px;
  top: -8px;
  text-align: center;
  padding-top: 5px;
  box-shadow: 0 1px 1px #ccc;
  cursor: pointer;
}
.addImg_card {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  width: 80px;
  height: 80px;
  .addImg_card_icon {
    font-size: 28px;
    color: #8c939d;
    width: 80px;
    height: 80px;
    line-height: 80px;
    text-align: center;
  }
}
.addImg_card:hover {
  border-color: #5e72f9;
}
</style>