<template>
  <div class="main_yuemu_view" v-loading="loading">
    <div class="main_yuemu_view_left">
      <div class="main_yuemu_view_left_bg">
        <div
          :class=" isSelected=='home'?'main_yuemu_view_left_view  main_yuemu_selected':'main_yuemu_view_left_view'"
          @click="onSwitchSelected('home')"
          v-bind:style="{background:backgroundColor}"
        >
          <el-carousel
            indicator-position="none"
            height="458px"
            class="main_yuemu_view_left_view_carousel"
            v-if="form.type=='img'"
          >
            <el-carousel-item v-for="item in this.form.imgList" :key="item">
              <el-image style="width: 100%; height: 100%" :src="fileUrl+item" fit="fill"></el-image>
            </el-carousel-item>
          </el-carousel>

          <div class="main_yuemu_view_left_view_content">
            <div class="main_yuemu_view_left_view_content_top">
              <div class="main_yuemu_view_left_view_content_top_name">
                <el-image
                  :src="fileUrl+placesInfo.logoUrl"
                  fit="fill"
                  style="width:30px;height:30px"
                ></el-image>&nbsp;&nbsp;
                <h2>{{placesInfo.groupName}}</h2>
              </div>
              <div class="main_yuemu_view_left_view_content_top_wifi">
                <i class="iconfont icon-wifi" style="font-size:30px;margin-top:-5px"></i>&nbsp;&nbsp;
                <div>
                  <p>WIFI: {{currencyForm.wifi_name}}</p>
                  <p>密码: {{currencyForm.wifi_password}}</p>
                </div>
              </div>
              <div class="main_yuemu_view_left_view_content_top_language">
                04/30&nbsp;星期六&nbsp;三月初八&nbsp;&nbsp;&nbsp;中文
                <i class="el-icon-caret-bottom"></i>
              </div>
            </div>
            <div class="main_yuemu_view_left_view_content_middle">
              <div
                :class=" isSelected=='card1'?'main_yuemu_view_left_view_content_middle_left  main_yuemu_selected':'main_yuemu_view_left_view_content_middle_left'"
                @click.stop="onSwitchSelected('card1',card1)"
                v-bind:style="{background:card1Background}"
              >
                <!-- 徽标 -->
                <div class="main_yuemu_view_left_view_content_middle_badge">1</div>
                <!-- 轮播图 -->
                <el-carousel
                  v-if="card1.type=='img'"
                  indicator-position="none"
                  height="250px"
                  class="main_yuemu_view_left_view_content_middle_carousel"
                >
                  <el-carousel-item v-for="(item,index) in this.card1.imgList" :key="index">
                    <el-image style="width: 100%; height: 100%" :src="fileUrl+item" fit="fill"></el-image>
                  </el-carousel-item>
                </el-carousel>
                <!-- 背景视频 -->
                <video
                  v-if="card1.type=='video'"
                  id="video"
                  :src="fileUrl+card1.videoUrl"
                  controls
                  autoplay
                  muted
                  class="main_yuemu_view_left_view_content_middle_video"
                ></video>

                <!-- 悬浮文字 -->
                <p
                  v-if="card1.style=='text'"
                  class="main_yuemu_view_left_view_content_middle_text_bottom"
                >{{card1.title}}</p>

                <!-- 悬浮图标 -->
                <div
                  v-if="card1.style=='icon'"
                  class="main_yuemu_view_left_view_content_middle_text_center"
                >
                  <el-image
                    style="width: 80px; height: 80px"
                    :src="fileUrl+card1.iconUrl"
                    fit="fill"
                  ></el-image>
                  <p>{{card1.title}}</p>
                </div>
                <!-- 悬浮二维码 -->
                <div
                  v-if="card1.style=='qr'"
                  class="main_yuemu_view_left_view_content_middle_text_center"
                >
                  <el-image style="width: 50px; height: 50px" :src="fileUrl+card1.qrImg" fit="fill"></el-image>
                </div>
              </div>

              <div class="main_yuemu_view_left_view_content_middle_card">
                <div
                  :class=" isSelected=='card2'?'main_yuemu_view_left_view_content_middle_card_one  main_yuemu_selected':'main_yuemu_view_left_view_content_middle_card_one'"
                  @click.stop="onSwitchSelected('card2',card2)"
                  v-bind:style="{background:card2Background}"
                >
                  <!-- 徽标 -->
                  <div class="one_badge">2</div>

                  <!-- 信息页面 -->
                  <div
                    v-if="card2.style=='info'"
                    class="main_yuemu_view_left_view_content_middle_info"
                  >
                    <div class="main_yuemu_view_left_view_content_middle_info_left">
                      <img
                        src="../../../assets/interface/weather_cloudy.png"
                        style="width:40px;height:40px"
                      />
                      <span>25°C</span>
                    </div>
                    <div class="main_yuemu_view_left_view_content_middle_info_right">
                      <div>
                        <div class="el-icon-location-outline">武汉</div>
                      </div>
                      <div style="margin-top:5px">
                        <p>多云</p>
                        <p style="margin-top:5px">16°C-30°C</p>
                      </div>
                    </div>
                  </div>

                  <!-- 轮播图 -->
                  <el-carousel
                    v-if="card2.type=='img'"
                    indicator-position="none"
                    height="80px"
                    class="one_carousel"
                  >
                    <el-carousel-item v-for="(item,index) in this.card2.imgList" :key="index">
                      <el-image style="width: 100%; height: 100%" :src="fileUrl+item" fit="fill"></el-image>
                    </el-carousel-item>
                  </el-carousel>
                  <!-- 悬浮文字 -->
                  <p v-if="card2.style=='text'" class="one_text_bottom">{{card2.title}}</p>

                  <!-- 悬浮图标 -->
                  <div v-if="card2.style=='icon'" class="one_text_center">
                    <el-image
                      style="width: 40px; height: 40px"
                      :src="fileUrl+card2.iconUrl"
                      fit="fill"
                    ></el-image>
                    <p>{{card2.title}}</p>
                  </div>
                  <!-- 悬浮二维码 -->
                  <div v-if="card2.style=='qr'" class="one_text_center">
                    <el-image
                      style="width: 50px; height: 50px"
                      :src="fileUrl+card2.qrImg"
                      fit="fill"
                    ></el-image>
                  </div>
                </div>

                <div
                  :class=" isSelected=='card3'?'main_yuemu_view_left_view_content_middle_card_two  main_yuemu_selected':'main_yuemu_view_left_view_content_middle_card_two'"
                  @click.stop="onSwitchSelected('card3',card3)"
                  v-bind:style="{background:card3Background}"
                >
                  <!-- 徽标 -->
                  <div class="two_badge">3</div>
                  <!-- 信息页面 -->
                  <div
                    v-if="card3.style=='info'"
                    class="main_yuemu_view_left_view_content_middle_info"
                  >
                    <div class="main_yuemu_view_left_view_content_middle_info_left">
                      <img
                        src="../../../assets/interface/weather_cloudy.png"
                        style="width:40px;height:40px"
                      />
                      <span>25°C</span>
                    </div>
                    <div class="main_yuemu_view_left_view_content_middle_info_right">
                      <div>
                        <div class="el-icon-location-outline">武汉</div>
                      </div>
                      <div style="margin-top:5px">
                        <p>多云</p>
                        <p style="margin-top:5px">16°C-30°C</p>
                      </div>
                    </div>
                  </div>
                  <!-- 轮播图 -->
                  <el-carousel
                    v-if="card3.type=='img'"
                    indicator-position="none"
                    height="80px"
                    class="two_carousel"
                  >
                    <el-carousel-item v-for="(item,index) in this.card3.imgList" :key="index">
                      <el-image style="width: 100%; height: 100%" :src="fileUrl+item" fit="fill"></el-image>
                    </el-carousel-item>
                  </el-carousel>
                  <!-- 悬浮文字 -->
                  <p v-if="card3.style=='text'" class="two_text_bottom">{{card3.title}}</p>

                  <!-- 悬浮图标 -->
                  <div v-if="card3.style=='icon'" class="two_text_center">
                    <el-image
                      style="width: 40px; height: 40px"
                      :src="fileUrl+card3.iconUrl"
                      fit="fill"
                    ></el-image>
                    <p>{{card3.title}}</p>
                  </div>
                  <!-- 悬浮二维码 -->
                  <div v-if="card3.style=='qr'" class="two_text_center">
                    <el-image
                      style="width: 50px; height: 50px"
                      :src="fileUrl+card3.qrImg"
                      fit="fill"
                    ></el-image>
                  </div>
                </div>
                <div
                  :class=" isSelected=='card4'?'main_yuemu_view_left_view_content_middle_card_three  main_yuemu_selected':'main_yuemu_view_left_view_content_middle_card_three'"
                  @click.stop="onSwitchSelected('card4',card4)"
                  v-bind:style="{background:card4Background}"
                >
                  <!-- 徽标 -->
                  <div class="three_badge">4</div>
                  <!-- 信息页面 -->
                  <div
                    v-if="card4.style=='info'"
                    class="main_yuemu_view_left_view_content_middle_info"
                  >
                    <div class="main_yuemu_view_left_view_content_middle_info_left">
                      <img
                        src="../../../assets/interface/weather_cloudy.png"
                        style="width:40px;height:40px"
                      />
                      <span>25°C</span>
                    </div>
                    <div class="main_yuemu_view_left_view_content_middle_info_right">
                      <div>
                        <div class="el-icon-location-outline">武汉</div>
                      </div>
                      <div style="margin-top:5px">
                        <p>多云</p>
                        <p style="margin-top:5px">16°C-30°C</p>
                      </div>
                    </div>
                  </div>
                  <!-- 轮播图 -->
                  <el-carousel
                    v-if="card4.type=='img'"
                    indicator-position="none"
                    height="80px"
                    class="three_carousel"
                  >
                    <el-carousel-item v-for="(item,index) in this.card4.imgList" :key="index">
                      <el-image style="width: 100%; height: 100%" :src="fileUrl+item" fit="fill"></el-image>
                    </el-carousel-item>
                  </el-carousel>
                  <!-- 悬浮文字 -->
                  <p v-if="card4.style=='text'" class="three_text_bottom">{{card4.title}}</p>

                  <!-- 悬浮图标 -->
                  <div v-if="card4.style=='icon'" class="three_text_center">
                    <el-image
                      style="width: 40px; height: 40px"
                      :src="fileUrl+card4.iconUrl"
                      fit="fill"
                    ></el-image>
                    <p>{{card4.title}}</p>
                  </div>
                  <!-- 悬浮二维码 -->
                  <div v-if="card4.style=='qr'" class="three_text_center">
                    <el-image
                      style="width: 50px; height: 50px"
                      :src="fileUrl+card4.qrImg"
                      fit="fill"
                    ></el-image>
                  </div>
                </div>
              </div>
            </div>

            <div class="main_yuemu_view_left_view_content_bottom">
              <div class="main_yuemu_view_left_view_content_bottom_list">
                <div
                  :class="isSelectedNavigation==index && isSelected=='apk' ?'main_yuemu_view_left_view_content_bottom_list_application main_yuemu_application_selected':'main_yuemu_view_left_view_content_bottom_list_application'"
                  v-for="(navigation,index) in navigationList"
                  :key="index"
                  @click.stop="onSwitchSelected('apk',index)"
                >
                  <el-image
                    :src="fileUrl+navigation.iconUrl"
                    fit="fill"
                    style="width:70px;height:70px"
                  ></el-image>
                  <span>{{navigation.title}}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 右侧操作栏 -->
    <div style="width: 400px;" v-show="!bodySize"></div>

    <div class="main_yuemu_view_right" v-show="isShowWindow">
      <div v-if="isSelected=='home'">
        <div class="main_yuemu_view_right_btn">
          <div>
            <i
              class="el-icon-arrow-right"
              style="font-weight:bold;cursor:pointer"
              @click="isShowWindow=!isShowWindow"
            ></i>&nbsp;背景设置
          </div>
          <el-button
            type="primary"
            round
            size="small"
            style="width:100px"
            @click="onSubmitEditMainInterface"
          >保存</el-button>
        </div>
        <el-form
          :model="form"
          ref="form"
          label-position="top"
          label-width="80px"
          size="small"
          class="main_yuemu_view_right_form"
        >
          <el-form-item label="背景类型">
            <el-radio v-model="form.type" label="img">图片</el-radio>
            <el-radio v-model="form.type" label="color">纯色</el-radio>
            <el-radio v-model="form.type" label="gradient">渐变</el-radio>
          </el-form-item>
          <el-form-item label="背景图片" class="main_yuemu_view_right_img_card" v-if="form.type=='img'">
            <el-row :gutter="0">
              <el-col
                :xl="12"
                :lg="12"
                :md="12"
                :sm="24"
                :xs="24"
                :offset="0"
                v-for="(item,index) in form.imgList"
                :key="index"
                style="margin-bottom:10px;"
              >
                <div class="main_yuemu_view_right_img">
                  <el-image style="width: 100px; height: 100px" :src="fileUrl+item" fit="contain"></el-image>
                  <label @click="delEditImgs(index)" class="delImgIcon">
                    <i class="el-icon-delete" style="transform:rotate(-45deg); color:#FFFFFF;"></i>
                  </label>
                </div>
              </el-col>
              <el-col
                :xl="12"
                :lg="12"
                :md="12"
                :sm="24"
                :xs="24"
                :offset="0"
                style="margin-bottom:10px;"
                v-if="form.imgList.length<9"
              >
                <el-dropdown placement="top">
                  <div class="addImg_card">
                    <i class="el-icon-plus addImg_card_icon"></i>
                  </div>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item
                      @click.native="dialogEditImgFileUpload=true"
                      style="padding:0"
                    >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;资源库选择&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</el-dropdown-item>
                    <el-dropdown-item style="padding:0">
                      <el-upload
                        style="text-align:center"
                        :action="actionUrl"
                        accept=".jpg, .jpeg, .png, .JPG, .JPEG"
                        :headers="header"
                        :data="{type:0,category:'图片'}"
                        :show-file-list="false"
                        :on-success="handleEditImgSuccess"
                        :before-upload="beforeEditImgUpload"
                        multiple
                        :limit="isMaxImgCount"
                      >本地上传</el-upload>
                    </el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </el-col>
            </el-row>
            <span>多个图以轮播形式展示最多9个</span>
          </el-form-item>
          <el-form-item label="纯色" v-if="form.type=='color'">
            <div
              style="border:1px solid #DCDFE6;display:flex;justify-content:space-between;width:150px "
            >
              <p>{{form.color}}</p>
              <el-color-picker v-model="form.color" @change="backgroundColor = form.color;"></el-color-picker>
            </div>
          </el-form-item>
          <el-form-item label="渐变" v-if="form.type=='gradient'">
            <el-form-item>
              <el-row :gutter="10">
                <el-col
                  :span="6"
                  :offset="0"
                  v-for="gradient in gradientList"
                  :key="gradient.id"
                  style="margin-bottom:10px"
                >
                  <div
                    :class="form.gradient.id==gradient.id ?'main_yuemu_view_right_gradient_selected  main_yuemu_view_right_gradient':'main_yuemu_view_right_gradient' "
                    v-bind:style="{background:'linear-gradient('+gradient.type+', '+gradient.color1+','+gradient.color2+')'}"
                    @click="selectedGradient(gradient)"
                  ></div>
                </el-col>
              </el-row>
            </el-form-item>
          </el-form-item>
        </el-form>
      </div>
      <!-- 右侧导航栏菜单 -->
      <div v-if="isSelected=='apk'">
        <Navigation
          :isSelectedNavigation="isSelectedNavigation"
          :isShowWindow="isShowWindow"
          :navigationList="navigationList"
          v-on:callbackNavigationList="onSubmitEditMainInterface"
          v-on:callbackAddNavigation="onSubmitAddNavigation"
          v-on:callbackIsShowWindow="onSubmitCallbackIsShowWindow"
          v-on:callbackIsSelected="onSubmitCallBackIsSelected"
        ></Navigation>
      </div>
      <!-- 右侧卡片栏菜单 -->
      <div v-if="isSelected !='home' && isSelected !='apk'">
        <InterfaceCard
          :cardForm="cardForm"
          :isShowWindow="isShowWindow"
          v-on:callbackIsShowWindow="onSubmitCallbackIsShowWindow"
          v-on:callbackGradient="onSubmitCallbackGradient"
          v-on:callbackColor="onSubmitCallBackColor"
          v-on:callbackCard="onSubmitEditMainInterface"
        ></InterfaceCard>
      </div>
    </div>

    <!-- 背景图片 -->
    <el-dialog
      :visible.sync="dialogEditImgFileUpload"
      :close-on-click-modal="false"
      width="55%"
      @closed="closeDialogEditImgFileUpload"
    >
      <span slot="title">资源库</span>
      <FileUpload
        :choice="false"
        :type="0"
        :count="this.isMaxImgCount"
        :category="'图片'"
        v-if="dialogEditImgFileUpload"
      ></FileUpload>
      <div slot="footer" class="dialog-footer-button">
        <el-button plain type="primary" @click="dialogEditImgFileUpload = false" size="small">取 消</el-button>
        <el-button type="primary" @click="onSubmitEditImg" size="small">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import {
  addMainInterfaceNavigationApi,
  getMainInterfaceBackgroundApi,
  editMainInterfaceApi,
  getGradientListApi,
  getMainInterfaceNavigationApi,
  getMainInterfaceCardApi,
  getCurrencyApi
} from "../../../api/data";
import FileUpload from "../../../components/FileUpload";
import Aes from "../../../utils/aes";
import Navigation from "../../../components/Navigation";
import InterfaceCard from "../../../components/InterfaceCard";
export default {
  components: {
    FileUpload,
    Navigation,
    InterfaceCard
  },
  data() {
    return {
      loading: true,
      isSelected: "home", //选中
      isSelectedResource: {
        category: ""
      },
      form: {
        title: "",
        content: "",
        groupId: "",
        imgUrl: "",
        imgList: [],
        videoUrl: "",
        type: "",
        color: ""
      },
      currencyForm: {}, //当前场所通用信息
      isSelectedNavigation: "", //选中的导航Index
      navigationList: [], //导航集合
      gradientList: [], //渐变色集合
      dialogEditImgFileUpload: false, //背景图片资源库
      bodySize: false, //当前窗口是否小于1024
      isShowWindow: true, //是否显示右侧操作窗口
      backgroundColor: "", //可视化界面背景色
      card1: {},
      card2: {},
      card3: {},
      card4: {},
      cardForm: {},
      card1Background: "",
      card2Background: "",
      card3Background: "",
      card4Background: ""
    };
  },
  watch: {
    "form.type"(newVal) {
      if (newVal == "color") {
        this.backgroundColor = this.form.color;
      } else if (newVal == "gradient") {
        this.backgroundColor =
          "linear-gradient(" +
          this.form.gradient.type +
          ", " +
          this.form.gradient.color1 +
          "," +
          this.form.gradient.color2 +
          ")";
      }
    },
    "card1.type"(newVal) {
      if (newVal == "color") {
        this.card1Background = this.card1.color;
      } else if (newVal == "gradient") {
        this.card1Background =
          "linear-gradient(" +
          this.card1.gradient.type +
          ", " +
          this.card1.gradient.color1 +
          "," +
          this.card1.gradient.color2 +
          ")";
      }
    },
    "card2.type"(newVal) {
      if (newVal == "color") {
        this.card2Background = this.card2.color;
      } else if (newVal == "gradient") {
        this.card2Background =
          "linear-gradient(" +
          this.card2.gradient.type +
          ", " +
          this.card2.gradient.color1 +
          "," +
          this.card2.gradient.color2 +
          ")";
      }
    },
    "card3.type"(newVal) {
      if (newVal == "color") {
        this.card3Background = this.card3.color;
      } else if (newVal == "gradient") {
        this.card3Background =
          "linear-gradient(" +
          this.card3.gradient.type +
          ", " +
          this.card3.gradient.color1 +
          "," +
          this.card3.gradient.color2 +
          ")";
      }
    },
    "card4.type"(newVal) {
      if (newVal == "color") {
        this.card4Background = this.card4.color;
      } else if (newVal == "gradient") {
        this.card4Background =
          "linear-gradient(" +
          this.card4.gradient.type +
          ", " +
          this.card4.gradient.color1 +
          "," +
          this.card4.gradient.color2 +
          ")";
      }
    }
  },
  props: {
    isDefault: {
      type: Boolean,
      default: false
    },
    isPrese: {
      typeL: Boolean,
      default: false
    },
    isTag: {
      type: String,
      default: ""
    }
  },
  computed: {
    //最大上传图片数
    isMaxImgCount() {
      return 9 - this.form.imgList.length;
    },
    placesInfo() {
      return JSON.parse(Aes.decode(this.$store.state.user.placesInfo));
    },
    header() {
      //上传头部POST请求头部带token信息验证登录状态
      return {
        accessToken: this.$store.state.user.authorization
      };
    },
    //图片显示地址
    fileUrl() {
      return this.$store.state.fileUrl;
    },
    //上传路径
    actionUrl() {
      return this.$store.state.fileUrl + "/file/upload";
    }
  },
  created() {
    this.initLoad();
    this.initCard();
    this.initNavigation();
    this.wathSize();
    this.initGradient();
    this.initCurrency();
  },
  methods: {
    //初始化背景
    initLoad() {
      getMainInterfaceBackgroundApi({
        groupId: this.placesInfo.groupId,
        tag: this.isTag == "" ? this.placesInfo.tag : this.isTag,
        isDefault: this.isDefault
      }).then(res => {
        this.loading = false;
        this.form = res.data;
      });
    },
    //初始化导航
    initNavigation() {
      getMainInterfaceNavigationApi({
        groupId: this.placesInfo.groupId,
        tag: this.isTag == "" ? this.placesInfo.tag : this.isTag,
        isDefault: this.isDefault
      }).then(res => {
        if (res) this.navigationList = res.data;
      });
    },
    //初始化卡片
    initCard() {
      getMainInterfaceCardApi({
        groupId: this.placesInfo.groupId,
        tag: this.isTag == "" ? this.placesInfo.tag : this.isTag,
        isDefault: this.isDefault
      }).then(res => {
        for (let i = 0; i < res.data.length; i++) {
          if (res.data[i].cardAddress == "card1") {
            this.card1 = res.data[i];
          } else if (res.data[i].cardAddress == "card2") {
            this.card2 = res.data[i];
          } else if (res.data[i].cardAddress == "card3") {
            this.card3 = res.data[i];
          } else if (res.data[i].cardAddress == "card4") {
            this.card4 = res.data[i];
          }
        }
      });
    },
    //查询通用信息
    initCurrency() {
      getCurrencyApi({ groupId: this.placesInfo.groupId }).then(res => {
        this.currencyForm = res.data;
      });
    },
    //初始化渐变色列表
    initGradient() {
      getGradientListApi().then(res => {
        if (res) this.gradientList = res.data;
      });
    },
    //选择渐变事件
    selectedGradient(obj) {
      this.form.gradient = obj;
      this.backgroundColor =
        "linear-gradient(" +
        obj.type +
        ", " +
        obj.color1 +
        "," +
        obj.color2 +
        ")";
    },
    //添加导航
    onSubmitAddNavigation() {
      this.form.tag = this.isTag == "" ? this.placesInfo.tag : this.isTag;
      editMainInterfaceApi({
        background: this.form,
        navigation: this.navigationList,
        card: {
          card1: this.card1,
          card2: this.card2,
          card3: this.card3,
          card4: this.card4
        },
        isPrese: this.isPrese,
        groupId: this.placesInfo.groupId
      }).then(res => {
        if (res.type != "error") {
          addMainInterfaceNavigationApi({
            tag: this.isTag == "" ? this.placesInfo.tag : this.isTag,
            groupId: this.placesInfo.groupId
          }).then(res => {
            if (res) {
              this.initLoad();
              this.initCard();
              this.initNavigation();
              this.$message.success(res.msg);
            }
          });
        }
      });
    },
    //提交编辑
    onSubmitEditMainInterface() {
      this.form.tag = this.isTag == "" ? this.placesInfo.tag : this.isTag;
      editMainInterfaceApi({
        background: this.form,
        navigation: this.navigationList,
        card: {
          card1: this.card1,
          card2: this.card2,
          card3: this.card3,
          card4: this.card4
        },
        isPrese: this.isPrese,
        groupId: this.placesInfo.groupId
      }).then(res => {
        if (res) {
          this.initLoad();
          this.initCard();
          this.initNavigation();
          this.$message.success(res.msg);
        }
      });
    },

    // 导航子页面返回是否折叠窗口
    onSubmitCallbackIsShowWindow(isShowWindow) {
      this.isShowWindow = isShowWindow;
    },
    //导航子页面返回选中的卡片
    onSubmitCallBackIsSelected() {
      // this.isSelected = "apk";
      // this.isSelectedNavigation = index;
    },
    //卡片选中渐变返回事件
    onSubmitCallbackGradient(obj) {
      if (this.isSelected == "card1") {
        this.card1Background =
          "linear-gradient(" +
          obj.gradient.type +
          ", " +
          obj.gradient.color1 +
          "," +
          obj.gradient.color2 +
          ")";
      } else if (this.isSelected == "card2") {
        this.card2Background =
          "linear-gradient(" +
          obj.gradient.type +
          ", " +
          obj.gradient.color1 +
          "," +
          obj.gradient.color2 +
          ")";
      } else if (this.isSelected == "card3") {
        this.card3Background =
          "linear-gradient(" +
          obj.gradient.type +
          ", " +
          obj.gradient.color1 +
          "," +
          obj.gradient.color2 +
          ")";
      } else if (this.isSelected == "card4") {
        this.card4Background =
          "linear-gradient(" +
          obj.gradient.type +
          ", " +
          obj.gradient.color1 +
          "," +
          obj.gradient.color2 +
          ")";
      }
    },
    //选择纯色
    onSubmitCallBackColor() {
      if (this.isSelected == "card1") {
        this.card1Background = this.card1.color;
      } else if (this.isSelected == "card2") {
        this.card2Background = this.card2.color;
      } else if (this.isSelected == "card3") {
        this.card3Background = this.card3.color;
      } else if (this.isSelected == "card4") {
        this.card4Background = this.card4.color;
      }
    },

    //上传图片完成事件
    handleEditImgSuccess(res) {
      var result = JSON.parse(Aes.decode(res));
      if (result.type == "success") {
        this.form.imgList.push(result.msg);
      } else {
        this.$message.error(result.msg);
      }
    },
    //上传图片前事件
    beforeEditImgUpload(file) {
      const isLt5M = file.size / 1024 / 1024 < 5;
      if (!isLt5M) {
        this.$message.warning("上传图片大小不能超过 5MB!");
      }
      return isLt5M;
    },
    //资源库图片添加完成事件
    onSubmitEditImg() {
      this.dialogEditImgFileUpload = false;
      if (this.$store.state.fileList.length > 0) {
        for (let i = 0; i < this.$store.state.fileList.length; i++) {
          this.form.imgList.push(this.$store.state.fileList[i].filePath);
        }
        this.$store.commit("setFileList", []);
      }
    },
    //图片窗口关闭事件
    closeDialogEditImgFileUpload() {
      this.$store.commit("setFileList", []);
    },
    //删除图片集合
    delEditImgs(index) {
      this.form.imgList.splice(index, 1);
    },

    //监听当前窗口大小调整占用右侧DIV
    wathSize() {
      if (document.body.clientWidth <= 1024) {
        this.bodySize = true;
      } else {
        this.bodySize = false;
      }
      window.addEventListener("resize", () => {
        if (document.body.clientWidth <= 1024) {
          this.bodySize = true;
        } else {
          this.bodySize = false;
        }
      });
    },
    //页面选中
    onSwitchSelected(isSelected, index) {
      this.isShowWindow = true;
      this.isSelectedNavigation = "";
      if (isSelected == "home") {
        this.isSelected = "home";
      } else if (isSelected == "apk") {
        this.isSelected = "apk";
        this.isSelectedNavigation = index;
      } else {
        this.isSelected = isSelected;
        this.cardForm = index;
      }
    }
  }
};
</script>
<style lang='scss'>
.main_yuemu_selected {
  outline: 2px dashed #025cdf;
  // border-radius: 6px;
}

.main_yuemu_view {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  width: 100%;
  height: calc(100vh - 60px);
  overflow: hidden;
}
.main_yuemu_view_left {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  .main_yuemu_view_left_bg {
    background: url("../../../assets/hotel_interface.png");
    background-size: cover;
    width: 100%;
    max-width: 810px;
    height: 532px;
    padding-left: 5px;
    padding-top: 4px;
  }
  .main_yuemu_view_left_view {
    width: 100%;
    max-width: 802px;
    height: 458px;
    // border: 2px solid #000000;
    // border-radius: 6px;
    cursor: pointer;
    position: relative;
    box-sizing: border-box;
    .main_yuemu_view_left_view_carousel {
      z-index: 1;
      // border-radius: 4px;
    }
    .main_yuemu_view_left_view_content {
      z-index: 2;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      padding: 20px;
      color: #ffffff;
      .main_yuemu_view_left_view_content_top {
        display: flex;
        justify-content: space-between;
        height: 10%;
        font-size: 12px;
        align-items: center;
        .main_yuemu_view_left_view_content_top_name {
          display: flex;
          align-items: center;
          width: 33%;
          h2 {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
        }
        .main_yuemu_view_left_view_content_top_wifi {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 33%;
        }
        .main_yuemu_view_left_view_content_top_language {
          width: 33%;
          text-align: right;
        }
      }

      .main_yuemu_view_left_view_content_middle {
        display: flex;
        justify-content: center;
        .main_yuemu_view_left_view_content_middle_left {
          width: 72%;
          height: 250px;
          border-radius: 6px;
          position: relative;
        }
        .main_yuemu_view_left_view_content_middle_carousel {
          border-radius: 6px;
        }
        .main_yuemu_view_left_view_content_middle_video {
          object-fit: fill;
          width: 100%;
          height: 100%;
        }
        .main_yuemu_view_left_view_content_middle_text_center {
          position: absolute;
          top: 35%;
          left: 40%;
          width: 100px;
          text-align: center;
          z-index: 2;
          p {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
        }

        .main_yuemu_view_left_view_content_middle_text_bottom {
          position: absolute;
          bottom: 0;
          left: 0;
          background: linear-gradient(transparent, rgba(0, 0, 0, 0.8));
          width: 100%;
          height: 40px;
          line-height: 50px;
          text-align: center;
          z-index: 2;
          border-radius: 0 0 6px 6px;
        }

        .main_yuemu_view_left_view_content_middle_badge {
          position: absolute;
          top: 0;
          left: 0;
          width: 20px;
          height: 20px;
          background: rgba($color: #ff0000, $alpha: 1);
          border-radius: 20px;
          color: #ffffff;
          z-index: 3;
          display: flex;
          align-items: center;
          justify-content: center;
          line-height: 16px;
        }

        .main_yuemu_view_left_view_content_middle_card {
          width: 28%;
          padding-left: 10px;
          position: relative;
          .main_yuemu_view_left_view_content_middle_card_img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border-radius: 6px;
            z-index: 0;
          }

          .main_yuemu_view_left_view_content_middle_card_one {
            height: 80px;
            border-radius: 6px;
            position: relative;
            .one_badge {
              position: absolute;
              top: 0;
              left: 0;
              width: 20px;
              height: 20px;
              background: rgba($color: #ff0000, $alpha: 1);
              border-radius: 20px;
              color: #ffffff;
              z-index: 3;
              display: flex;
              align-items: center;
              justify-content: center;
              line-height: 16px;
            }
            .one_carousel {
              border-radius: 6px;
            }
            .one_text_center {
              position: absolute;
              top: 15%;
              left: 25%;
              width: 100px;
              text-align: center;
              z-index: 2;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
              p {
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
              }
            }
            .one_text_bottom {
              position: absolute;
              bottom: 0;
              left: 0;
              background: linear-gradient(transparent, rgba(0, 0, 0, 0.8));
              width: 100%;
              height: 30px;
              line-height: 40px;
              text-align: center;
              z-index: 2;
              border-radius: 0 0 6px 6px;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            }
          }
          .main_yuemu_view_left_view_content_middle_card_two {
            height: 80px;
            border-radius: 6px;
            position: relative;
            margin: 5px 0;
            .two_badge {
              position: absolute;
              top: 0;
              left: 0;
              width: 20px;
              height: 20px;
              background: rgba($color: #ff0000, $alpha: 1);
              border-radius: 20px;
              color: #ffffff;
              z-index: 3;
              display: flex;
              align-items: center;
              justify-content: center;
              line-height: 16px;
            }
            .two_carousel {
              border-radius: 6px;
            }
            .two_text_center {
              position: absolute;
              top: 15%;
              left: 25%;
              width: 100px;
              text-align: center;
              z-index: 2;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
              p {
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
              }
            }
            .two_text_bottom {
              position: absolute;
              bottom: 0;
              left: 0;
              background: linear-gradient(transparent, rgba(0, 0, 0, 0.8));
              width: 100%;
              height: 30px;
              line-height: 40px;
              text-align: center;
              z-index: 2;
              border-radius: 0 0 6px 6px;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            }
          }
          .main_yuemu_view_left_view_content_middle_card_three {
            height: 80px;
            border-radius: 6px;
            position: relative;
            .three_badge {
              position: absolute;
              top: 0;
              left: 0;
              width: 20px;
              height: 20px;
              background: rgba($color: #ff0000, $alpha: 1);
              border-radius: 20px;
              color: #ffffff;
              z-index: 3;
              display: flex;
              align-items: center;
              justify-content: center;
              line-height: 16px;
            }
            .three_carousel {
              border-radius: 6px;
            }
            .three_text_center {
              position: absolute;
              top: 15%;
              left: 25%;
              width: 100px;
              text-align: center;
              z-index: 2;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
              p {
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
              }
            }
            .three_text_bottom {
              position: absolute;
              bottom: 0;
              left: 0;
              background: linear-gradient(transparent, rgba(0, 0, 0, 0.8));
              width: 100%;
              height: 30px;
              line-height: 40px;
              text-align: center;
              z-index: 2;
              border-radius: 0 0 6px 6px;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            }
          }
        }
      }

      .main_yuemu_view_left_view_content_bottom {
        position: absolute;
        left: 20px;
        bottom: 0;
        width: 95%;
        .main_yuemu_view_left_view_content_bottom_list {
          display: flex;
          white-space: nowrap;
          overflow-x: auto;
          overflow-y: hidden;
          .main_yuemu_application_selected {
            background: rgba(127, 147, 177, 1) !important;
            border: 1px solid rgba(255, 255, 255, 1) !important;
          }
          .main_yuemu_view_left_view_content_bottom_list_application {
            background: rgba(127, 147, 177, 0.5);
            border: 1px solid rgba(255, 255, 255, 0.5);
            padding: 10px 20px;
            display: flex;
            flex-direction: column;
            align-items: center;
            border-radius: 6px;
            margin-right: 10px;
            margin-bottom: 5px;
            position: relative;
            img {
              border-radius: 6px;
            }
            span {
              text-align: center;
              width: 70px;
              font-size: 12px;
              margin-top: 5px;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            }
          }
        }

        .main_yuemu_view_left_view_content_bottom_list::-webkit-scrollbar {
          height: 10px;
        }
        .main_yuemu_view_left_view_content_bottom_list:hover::-webkit-scrollbar-thumb {
          background-color: rgba(0, 0, 0, 0.8);
        }
        .main_yuemu_view_left_view_content_bottom_list::-webkit-scrollbar-thumb {
          background-color: rgba(0, 0, 0, 0.3);
          border-radius: 10px;
        }
      }
    }
  }
}

.main_yuemu_view_right {
  background: #ffffff;
  border-top: 1px solid #d7dae2;
  width: 20%;
  height: 100%;
  min-width: 300px;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 9;
  .el-form-item__label {
    color: #9da3ac;
  }
  .main_yuemu_view_right_form {
    padding: 20px;
    overflow-y: scroll;
    height: calc(100vh - 120px);
    width: 100%;
  }
  .main_yuemu_view_right_form::-webkit-scrollbar {
    width: 6px;
  }
  .main_yuemu_view_right_form:hover::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.8);
  }
  .main_yuemu_view_right_form::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.3);
    border-radius: 6px;
  }
  .main_yuemu_view_right_btn {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 700;
    border-bottom: 1px solid #d7dae2;
    padding: 10px;
  }

  .main_yuemu_view_right_gradient {
    width: 50px;
    height: 50px;
    cursor: pointer;
    border-radius: 4px;
  }
  .main_yuemu_view_right_gradient_selected {
    border: 1px solid #909399;
    box-shadow: 0 2px 12px 0 #619aec8c;
  }

  .main_yuemu_view_right_gradient:hover {
    box-shadow: 0 2px 12px 0 #619aec8c;
  }

  .main_yuemu_view_right_img_card {
    .main_yuemu_view_right_img {
      position: relative;
      cursor: pointer;
      overflow: hidden;
      width: 100px;
      height: 100px;
      background: rgba(235, 235, 235, 0.5);
      border-radius: 6px;
      border: 1px dashed #d9d9d9;
    }
    .delImgIcon {
      width: 50px;
      height: 30px;
      background: #f56c6c;
      transform: rotate(45deg);
      position: absolute;
      right: -20px;
      top: -8px;
      text-align: center;
      padding-top: 5px;
      box-shadow: 0 1px 1px #ccc;
      cursor: pointer;
    }
    .addImg_card {
      border: 1px dashed #d9d9d9;
      border-radius: 6px;
      cursor: pointer;
      position: relative;
      overflow: hidden;
      width: 100px;
      height: 100px;
      .addImg_card_icon {
        font-size: 28px;
        color: #8c939d;
        width: 100px;
        height: 100px;
        line-height: 100px;
        text-align: center;
      }
    }
    .addImg_card:hover {
      border-color: #5e72f9;
    }
  }
}
.main_yuemu_view_left_view_content_middle_info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 4;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  padding: 5px;
  .main_yuemu_view_left_view_content_middle_info_left {
    display: flex;
    justify-content: center;
    align-items: center;
    span {
      font-size: 26px;
    }
  }
  .main_yuemu_view_left_view_content_middle_info_right {
    text-align: right;
    font-size: 12px;
  }
}
</style>